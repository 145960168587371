'use strict';

angular.module('kljDigitalLibraryApp')
    .service('NotificationsService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {

            var baseUrl = '/api/notification';

            this.getList = function(payload) {

                var deferred = $q.defer();

                common.callApi('GET',
                    baseUrl + '/getAll',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload)

                .then(function(response) {

                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }

        }
    ]);